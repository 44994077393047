import Vue from 'vue'
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
Vue.use(VueRouter)
const routes = [
	//首页
	{
		path: '/',
		name: 'home',
		component: () => import( /* webpackChunkName: "about" */ '../views/HomeView.vue')
	},
	//登录
	{
		path: '/login',
		name: 'login',
		component: () => import( /* webpackChunkName: "about" */ '../views/login/login.vue')
	},
	//注册
	{
		path: '/register',
		name: 'register',
		component: () => import( /* webpackChunkName: "about" */ '../views/login/register.vue')
	},
	//忘记密码
	{
		path: '/Forgotpassword',
		name: 'Forgotpassword',
		component: () => import( /* webpackChunkName: "about" */ '../views/login/Forgotpassword.vue')
	},
	//设计师工作台
	{
		path: '/DesignerWorkbench',
		name: 'DesignerWorkbench',
		component: () => import( /* webpackChunkName: "about" */ '../views/DesignerWorkbench.vue')
	},
	//个人中心首页
	{
		path: '/selfcenter',
		name: 'selfcenter',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/selfcenter.vue')
	},
	//个人中心设计师须知
	{
		path: '/selfcenterxuzhi',
		name: 'selfcenterxuzhi',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/selfcenterxuzhi.vue')
	},
	//接单大厅
	{
		path: '/Orderreceptionhall',
		name: 'Orderreceptionhall',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/Orderreceptionhall.vue')
	},
	//订单详情
	{
		path: '/Orderdetails',
		name: 'Orderdetails',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/Orderdetails.vue')
	},
	//交稿
	{
		path: '/Submission',
		name: 'Submission',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/Submission.vue')
	},
	//订单管理（进行中）
	{
		path: '/Ordermanagement',
		name: 'Ordermanagement',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/Ordermanagement.vue')
	},
	//全部订单
	{
		path: '/Allorders',
		name: 'Allorders',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/Allorders.vue')
	},
	//申诉订单
	{
		path: '/Appealorder',
		name: 'Appealorder',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/Appealorder.vue')
	},
	//催单消息
	{
		path: '/Remindermessage',
		name: 'Remindermessage',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/Remindermessage.vue')
	},
	//数据报表
	{
		path: '/Datareport',
		name: 'Datareport',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/Datareport.vue')
	},
	//订单明细
	{
		path: '/ordermx',
		name: 'ordermx',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/ordermx.vue')
	},
	//个人收益
	{
		path: '/Personalbenefits',
		name: 'Personalbenefits',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/Personalbenefits.vue')
	},
	//账户管理
	{
		path: '/AccountManagement',
		name: 'AccountManagement',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/AccountManagement.vue')
	},
	//公告通知
	{
		path: '/news',
		name: 'news',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/news.vue')
	},
	//个人信息
	{
		path: '/personalinformation',
		name: 'personalinformation',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/personalinformation.vue')
	},
	//易学堂
	{
		path: '/YiXuetang',
		name: 'YiXuetang',
		component: () => import( /* webpackChunkName: "about" */ '../views/YiXuetang.vue')
	},
	//关于我们
	{
		path: '/aboutus',
		name: 'aboutus',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/aboutus.vue')
	},
	//账户管理
	{
		path: '/messageManagement',
		name: 'messageManagement',
		component: () => import( /* webpackChunkName: "about" */ '../views/commonproblem/messageManagement.vue')
	},
	//实名认证
	{
		path: '/Realnameauthentication',
		name: 'Realnameauthentication',
		component: () => import( /* webpackChunkName: "about" */ '../views/commonproblem/Realnameauthentication.vue')
	},
	//设计任务
	{
		path: '/designassignment',
		name: 'designassignment',
		component: () => import( /* webpackChunkName: "about" */ '../views/commonproblem/designassignment.vue')
	},
	//隐私政策
	{
		path: '/Privacy',
		name: 'Privacy',
		component: () => import( /* webpackChunkName: "about" */ '../views/commonproblem/Privacy.vue')
	},
	//服务协议
	{
		path: '/ServiceAgreement',
		name: 'ServiceAgreement',
		component: () => import( /* webpackChunkName: "about" */ '../views/commonproblem/ServiceAgreement.vue')
	},
	//保护政策
	{
		path: '/protectionpolicy',
		name: 'protectionpolicy',
		component: () => import( /* webpackChunkName: "about" */ '../views/commonproblem/protectionpolicy.vue')
	},
	//版权
	{
		path: '/copyright',
		name: 'copyright',
		component: () => import( /* webpackChunkName: "about" */ '../views/commonproblem/copyright.vue')
	},
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	//选择公司
	{
		path: '/selectcompany',
		name: 'selectcompany',
		component: () => import( /* webpackChunkName: "about" */ '../views/selectcompany.vue')
	},
	//合同管理（用户端）
	{
		path: '/hetongguanli',
		name: 'hetongguanli',
		component: () => import( /* webpackChunkName: "about" */ '../views/hetongguanli.vue')
	},
	//编辑发布
	{
		path: '/fabu',
		name: 'fabu',
		component: () => import( /* webpackChunkName: "about" */ '../views/fabu.vue')
	},
	//项目详情
	{
		path: '/xiangmudetail',
		name: 'xiangmudetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/xiangmudetail.vue')
	},
	//项目进度
	{
		path: '/xiangmujingdu',
		name: 'xiangmujingdu',
		component: () => import( /* webpackChunkName: "about" */ '../views/xiangmujingdu.vue')
	},
	//阶段内容
	{
		path: '/jieduanneirong',
		name: 'jieduanneirong',
		component: () => import( /* webpackChunkName: "about" */ '../views/jieduanneirong.vue')
	},
	//近期项目（负责人）
	{
		path: '/fuzeren',
		name: 'fuzeren',
		component: () => import( /* webpackChunkName: "about" */ '../views/fuzeren.vue')
	},
	//任务
	{
		path: '/renwu',
		name: 'renwu',
		component: () => import( /* webpackChunkName: "about" */ '../views/renwu.vue')
	},
	
	
	//编辑资料
	{
		path: '/edituser',
		name: 'edituser',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/edituser.vue')
	},
	//服务报表
	{
		path: '/fuwubaobiao',
		name: 'fuwubaobiao',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/fuwubaobiao.vue')
	},
	//服务报表(项目情况)
	{
		path: '/fwbbxiangmuqingkuang',
		name: 'fwbbxiangmuqingkuang',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/fwbbxiangmuqingkuang.vue')
	},
	//服务报表(项目情况)
	{
		path: '/fwbbxiangmuliebiao',
		name: 'fwbbxiangmuliebiao',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/fwbbxiangmuliebiao.vue')
	},
	//服务报表(二级服务报表)
	{
		path: '/fwbbfuwubaobiao',
		name: 'fwbbfuwubaobiao',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/fwbbfuwubaobiao.vue')
	},
	//我的案件
	{
		path: '/selfanjian',
		name: 'selfanjian',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/selfanjian.vue')
	},
	//关于平台
	{
		path: '/aboutpingtai',
		name: 'aboutpingtai',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/aboutpingtai.vue')
	},
	//律师列表
	{
		path: '/lvshilist',
		name: 'lvshilist',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/lvshilist.vue')
	},
	//律师详情
	{
		path: '/lvshidetail',
		name: 'lvshidetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/lvshidetail.vue')
	},
	// //人员管理
	// {
	// 	path: '/selfuserguanli',
	// 	name: 'selfuserguanli',
	// 	component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/selfuserguanli.vue')
	// },
	// //人员管理
	// {
	// 	path: '/selfuserguanli',
	// 	name: 'selfuserguanli',
	// 	component: () => import( /* webpackChunkName: "about" */ '../views/selfcenter/selfuserguanli.vue')
	// },
	
	
	
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})
//跳转后返回页面顶部
router.afterEach(() => {
    window.scrollTo(0,0);
})

export default router
