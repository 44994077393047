<template>
	<div class="hello">
		<div class="login_header">
			<div class="login_header_content">
				<div class="login_header_one" @click="headerclick(0)">
					<img :src="basedetail.web_site_logo" alt="">
					<div class="login_header_one_text">
						<img src="../assets/fl6.png" alt="" />
						<p>{{basedetail.web_site_description}}</p>
					</div>
				</div>
				<div class="login_header_two">
					<div v-if="userInfo ==''">
						<div class="login_header_two_list"  @click="loginclick(1)">
							<span>登录</span>
						</div>
						<div class="login_header_two_list_register" @click="loginclick(2)">
							<span>注册</span>
						</div>
					</div>
					<div class="fl_login_five" v-if="userInfo">
						<div class="fl_login_five_list_one" @click="gomessage(1)">
							<img src="../assets/fl21.png" alt="" />
							<div class="fl_login_five_list_one_number" v-if="totalnumber>0">
								<span>{{totalnumber}}</span>
							</div>
						</div>
						<div class="fl_login_five_list_one" @click="gomessage(2)">
							<img src="../assets/fl22.png" alt="" />
							<div class="fl_login_five_list_one_number" v-if="ordernum>0">
								<span>{{ordernum}}</span>
							</div>
						</div>
						<div class="fl_login_five_list_two" @click="goselfcenter">
							<img :src="userInfo.avatar" alt="" />
						</div>
						<div class="fl_login_five_list_three" @click="goselfcenter">
							<span>{{userInfo.nickname}}</span>
						</div>
						<div class="fl_login_five_list_five" @click="checkshow = !checkshow">
							<img src="../assets/fl23.png" alt="" />
						</div>
						<div class="fl_login_five_list_six" v-if="checkshow">
							<div class="fl_login_five_list_six_list" style="color: #0068B7;" @click="editshow=true">
								<img src="../assets/fl24.png" alt="" />
								<span>修改密码</span>
							</div>
							<div class="fl_login_five_list_six_list" @click="clearlogin">
								<img src="../assets/fl25.png" alt="" />
								<span>退出</span>
							</div>
						</div>
					</div>
				</div>
				<div class="header_two">
					<div class="header_two_list" :class="{'header_two_list_now':headerindex==0}" @click="headerclick(0)">
						<span>首页</span>
					</div>
					<div class="header_two_list" :class="{'header_two_list_now':headerindex==1}" @click="headerclick(1)">
						<span>设计师工作台</span>
					</div>
					<div class="header_two_list" :class="{'header_two_list_now':headerindex==2}" @click="headerclick(2)">
						<span>易学堂</span>
					</div>
					<div class="header_two_list" :class="{'header_two_list_now':headerindex==3}" @click="headerclick(3)">
						<span>关于我们</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 修改密码 -->
		<div class="edit_password" v-if="editshow">
			<div class="edit_password_content">
				<div class="edit_password_content_header">
					<span>修改密码</span>
				</div>
				<div class="edit_password_content_list">
					<div class="edit_password_content_list_l">
						<span>原密码</span>
					</div>
					<div class="edit_password_content_list_r">
						<input type="text" v-model="oldpassword" placeholder="请输入原密码" maxlength="12">
					</div>
				</div>
				<div class="edit_password_content_list">
					<div class="edit_password_content_list_l">
						<span>新密码</span>
					</div>
					<div class="edit_password_content_list_r">
						<input type="text" v-model="oncepassword" placeholder="请输入新密码" maxlength="12">
					</div>
				</div>
				<div class="edit_password_content_list">
					<div class="edit_password_content_list_l">
						<span>新密码</span>
					</div>
					<div class="edit_password_content_list_r">
						<input type="text" v-model="twicepassword" placeholder="请再次输入新密码" maxlength="12">
					</div>
				</div>
				<div class="edit_password_content_bottom">
					<div class="edit_password_content_bottom_l" @click="editshow=false">
						<span>关闭</span>
					</div>
					<div class="edit_password_content_bottom_r" @click="editpassword">
						<span>保存</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	export default {
		name: 'HelloWorld',
		data() {
			return {
				userInfo: "", //个人中心信息
				editshow:false,//修改密码弹窗
				login_outshow: false, //退出登录弹窗
				checkshow:false,//个人资料修改弹窗
				headerindex:0,//头部状态
				ContactUs:"",//平台信息
				oldpassword:"",//旧密码
				oncepassword:"",//第一次输入密码
				twicepassword:"",//再次输入密码
				messagedetail:[],//消息数字
				totalnumber:0,//未读系统消息number
				ordernum:0,//未读催单信息
				basedetail:"",//基础配置信息
				pageurl:"",//获取页面链接
			}
		},
		mounted() {
			if (localStorage.getItem("userInfo")) {
				this.get_user_info(); //获取个人中心信息
				this.getSystemMsgType();//系统消息-获取消息类型+最新消息
			} else {
				this.userInfo = "";
			}
			if(localStorage.getItem("headerindex")){
				this.headerindex = localStorage.getItem("headerindex");
			}else{
				this.headerindex = 0;
			}
			this.pageurl = window.location.href;
		},
		created() {
			// this.linktab(); //判断是移动端还是pc端
			this.getContactUs()
			this.base_config();//基本配置
		},
		methods: {
			//基本配置
			base_config(){
				this.$http({
					url: '668f8f9e24d33',
					method: 'get',
				}).then((res) => {
					if (res.data.code == 1) {
						this.basedetail = res.data.data;
					}
				});
			},
			
			//系统消息-获取消息类型+最新消息
			getSystemMsgType(){
				this.$http({
					url: '6424235abdc3c',
					method: 'get',
				}).then((res) => {
					if (res.data.code == 1) {
						this.totalnumber = res.data.data.message[0].num+res.data.data.message[1].num;
						this.ordernum = res.data.data.message[2].num;
					}
				});
			},
			
			//跳转登陆
			loginclick(index){
				if(index==1){
					this.$router.push({path:"login"});
				}else{
					this.$router.push({path:"register"});
				}
			},
			
			//头部切换事件
			headerclick(index){
				let _this = this;
				localStorage.setItem("headerindex",index);
				if(index==0){
					this.$router.push({"path":"/"});
				}else if(index==1){
					if(_this.userInfo==""){
						_this.$router.push({"path":"/DesignerWorkbench"});
					}else{
						localStorage.setItem("selfleftyjindex",1);
						localStorage.setItem("selfleftejindex",1);
						_this.$router.push({"path":"selfcenter"});
					}
				}else if(index==2){
					if(_this.userInfo==""){
						_this.$router.push({"path":"login"});
					}else{
						_this.$router.push({"path":"/YiXuetang"});
					}
				}else if(index==3){
					localStorage.setItem("yijicheck",1);
					localStorage.setItem("leftindex",1);
					this.$router.push({"path":"/aboutus"});
				}
			},
			
			//获取个人中心
			get_user_info() {
				this.$http({
					url: '5c78c4772da97',
					method: 'get',
				}).then((res) => {
					if (res.data.code == 1) {
						this.userInfo = res.data.data;
					}
				});
			},
			
			//联系我们
			getContactUs(){
				this.$http({
					url: '661f907319645',
					method: 'post',
					data: JSON.stringify({
						
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.ContactUs = res.data.data;
					}
				});
			},

			// linktab() {
			// 	let goUrl = this.isMobile();
			// 	if (goUrl === 1) {
			// 		//移动端地址
			// 	} else {
			// 		//PC地址
			// 		// location = "https://www.denlike.com/";
			// 	}
			// },

			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				let goUrl = flag ? 1 : 0;
				return goUrl;
			},
			
			//修改密码
			editpassword(){
				let _this = this;
				if(_this.oldpassword==""){
					this.$message({
						message: "请输入旧密码",
						type: "error"
					})
					return;
				}
				if(_this.oncepassword==""){
					this.$message({
						message: "请输入新密码",
						type: "error"
					})
					return;
				}
				if(_this.twicepassword==""){
					this.$message({
						message: "请输入确认密码",
						type: "error"
					})
					return;
				}
				if(_this.twicepassword != _this.oncepassword){
					this.$message({
						message: "新密码和确认密码不一致",
						type: "error"
					})
					return;
				}
				this.$http({
					url: '5da9ab4c4c7af',
					method: 'post',
					data: JSON.stringify({
						security_code:this.oldpassword,
						password:this.oncepassword,
						password_code:this.twicepassword,
					})
				}).then((res) => {
					if (res.data.code == 1) {
						_this.$message({
							message: "修改成功",
							type: "success"
						})
						localStorage.clear();
						localStorage.setItem("headerindex",0);
						_this.$router.push({"path":"login"});
					}
				});
			},
			
			//退出登录
			clearlogin(){
				let _this = this;
				this.$http({
					url: '65e546aa76647',
					method: 'post',
					data: JSON.stringify({
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.$message({
							message: "退出成功",
							type: "success"
						})
						localStorage.clear();
						if(_this.pageurl=="http://www.yisheji.com.cn/#/"){
							window.location.reload()
						}else{
							localStorage.clear();
							localStorage.setItem("headerindex",0);
							_this.$router.push({"path":"/"});
						}
					}
				});
			},
			
			//跳转个人中心
			goselfcenter(){
				localStorage.setItem("selfleftyjindex",1);
				this.$router.push({"path":"selfcenter"});
			},
			
			//跳转消息
			gomessage(index){
				if(index==1){
					localStorage.setItem("selfleftyjindex",7);
					this.$router.push({"path":"news"})
				}else{
					localStorage.setItem("selfleftyjindex",3);
					localStorage.setItem("updownshow0","true");
					localStorage.setItem("selfleftejindex",6);
					this.$router.push({"path":"Remindermessage"})
				}
			},
		},
	}
</script>
<style scoped>
	*{margin: 0;padding: 0;}
	.login_header {
		width: 100%;
		height: 92px;
		cursor: pointer;
		background: #F0F7FF;
	}

	.login_header_content {
		width:100%;
		height: 100%;
		position: relative;
		margin: 0 auto;
	}

	.login_header_one {
		width: auto;
		height: 100%;
		float: left;
	}

	.login_header_one img {
		width: 64px;
		height: 64px;
		border-radius: 32px;
		margin-left: 40px;
		margin-right: 16px;
		display: block;
		float: left;
		margin-top: 14px;
	}
	.login_header_one_text{
		width:150px;
		height: auto;
		float: left;
	}
	.login_header_one_text img{
		width: 96px;
		height: 40px;
		display: block;
		margin-left: 0;
	}
	.login_header_one_text p{
		font-weight: 400;
		font-size: 16px;
		color: #666666;
		display: block;
		float: left;
		margin-top: 5px;
	}
	.login_header_two {
		width: auto;
		height:92px;
		float: right;
		line-height: 92px;
		position: relative;
		float: right;
		margin-right: 50px;
	}
	.login_header_two_list{
		width: 72px;
		height: 35px;
		background: #0068B7;
		border-radius: 6px 6px 6px 6px;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		text-align: center;
		line-height: 35px;
		margin-top: 28px;
		float: left;
	}
	.login_header_two_list_register{
		width: 72px;
		height: 35px;
		border-radius: 6px 6px 6px 6px;
		font-weight: 400;
		font-size: 14px;
		color: #0068B7;
		text-align: center;
		line-height: 35px;
		margin-top: 28px;
		border: 1px solid #0068B7;
		float: left;
		margin-left: 30px;
	}
	.login_header_two_list_news{
		width: 20px;
		height: 20px;
		margin-top: 20px;
	}
	.login_header_two_list_news_num{
		width:12px;
		height:12px;
		display: block;
		background: #FE2B38;
		border-radius: 50%;
		color: #FFFFFF;
		position: absolute;
		top: 15px;
		left: 10px;
		font-weight: 400;
		font-size: 10px;
		color: #FFFFFF;
		text-align: center;
		line-height:12px;
	}
	.login_header_two_list_logo{
		width: 32px;
		height: 32px;
		border-radius: 50%;
		margin-top: 14px;
		display: block;
		float: left;
	}
	.login_header_two_list_qh{
		width: 74px;
		height: 24px;
		background: #0077FF;
		border-radius: 20px;
		font-weight: 400;
		font-size: 11px;
		color: #FFFFFF;
		line-height: 24px;
		text-align: center;
		margin-top: 20px;
		float: left;
	}
	.login_header_two_list_qh img{
		width: 10px;
		height: 10px;
	}
	.login_header_two_list_border{
		width: 1px;
		height: 24px;
		background-color:#F0F1F5;
		float: left;
		margin-left: 16px;
		margin-top: 20px;
	}
	/* 退出登录弹窗 */
	.login_out {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 9;
	}

	.login_out_content {
		width: 460px;
		height: 320px;
		background: #FFFFFF;
		border-radius: 0px 0px 0px 0px;
		border: 1px solid #707070;
		margin: 200px auto;
	}

	.login_out_header {
		width: 460px;
		height: 46px;
		background: #F9F9F9;
		border_bottom: 1px solid #E2E2E2;
		font-size: 16px;
		font-weight: 500;
		color: #333333;
		line-height: 46px;
	}

	.login_out_header_border {
		width: 4px;
		height: 14px;
		background: rgb(254, 43, 56);
		border-radius: 2px 2px 2px 2px;
		float: left;
		margin-left: 24px;
		margin-top: 16px;
		margin-right: 16px;
	}

	.login_out_content img {
		width: 54px;
		height: 54px;
		display: block;
		margin: 40px auto;
	}

	.login_out_content p {
		font-size: 14px;
		font-weight: 400;
		color: #333333;
		text-align: center;
	}

	.login_out_cance {
		width: 140px;
		height: 42px;
		background: #EEEEEE;
		border-radius: 4px 4px 4px 4px;
		text-align: center;
		line-height: 42px;
		float: left;
		margin-left: 78px;
		margin-top: 41px;
		cursor: pointer;
	}

	.login_out_cfirem {
		width: 140px;
		height: 42px;
		background:rgb(254, 43, 56);
		border-radius: 4px 4px 4px 4px;
		text-align: center;
		line-height: 42px;
		float: left;
		margin-left: 24px;
		color: #FFFFFF;
		margin-top: 41px;
		cursor: pointer;
	}
	.header_two{
		width:auto;
		float: left;
		margin-left: 112px;
	}
	.header_two_list{
		width: auto;
		font-weight: 400;
		font-size: 16px;
		color: #333333;
		float: left;
		margin-right: 44px;
		line-height: 92px;
	}
	.header_two_list_now{
		font-weight: 600 !important;
		font-size: 20px;
		color: #1A1A1A !important;
	}
	.fl_login_five{
		width: auto;
		height: 40px;
		float: right;
		margin-top: 26px;
		cursor: pointer;
		position: relative;
	}
	.fl_login_five_list_one{
		width:32px;
		height:32px;
		float: left;
		margin-right: 32px;
		margin-top: 4px;
		position: relative;
	}
	.fl_login_five_list_one_number{
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-color: red;
		color: #FFF;
		position: absolute;
		right: 0;
		top: 0;
		text-align: center;
		line-height: 16px;
	}
	.fl_login_five_list_one img{
		width: 32px;
		height: 32px;
		display: block;
	}
	.fl_login_five_list_two{
		width: 40px;
		height: 40px;
		float: left;
		border-radius: 50%;
	}
	.fl_login_five_list_two img{
		width: 100%;
		height: 100%;
		display: block;
		border-radius: 50%;
	}
	.fl_login_five_list_three{
		width: auto;
		height: 40px;
		line-height: 40px;
		font-weight: 400;
		font-size: 18px;
		color: #000000;
		float: left;
		margin-left: 14px;
	}
	.fl_login_five_list_five{
		width: auto;
		height: 40px;
		float: left;
		line-height: 40px;
		margin-left: 16px;
	}
	.fl_login_five_list_five img{
		width: 12px;
		height: 12px;
		position: relative;
		top: 4px;
	}
	.fl_login_five_list_six{
		width: 137px;
		height: 92px;
		background: #FFFFFF;
		box-shadow: 3px 3px 12px 1px rgba(0,0,0,0.07);
		border-radius: 8px;
		border: 1px solid #EEEEEE;
		position: absolute;
		right: 0;
		top: 50px;
		z-index: 999;
	}
	.fl_login_five_list_six_list{
		width: 100%;
		height: 46px;
		line-height: 46px;
		font-weight: 500;
		font-size: 16px;
		color: #666666;
	}
	.fl_login_five_list_six_list img{
		width: 24px;
		height: 24px;
		position: relative;
		top: 6px;
		margin-left: 12px;
		margin-right: 6px;
	}
	.edit_password{
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgb(0, 0, 0,0.5);
		z-index: 999;
		color: #FFF;
	}
	.edit_password_content{
		width: 584px;
		height: 351px;
		background: #FFF;
		border-radius: 12px;
		margin: 15% auto;
	}
	.edit_password_content_header{
		width:100%;
		height: 22px;
		font-weight: 500;
		font-size: 22px;
		color: #333333;
		text-align: center;
		padding-top: 32px;
	}
	.edit_password_content_list{
		width: 100%;
		height: 42px;
		float: left;
		margin-top: 24px;
	}
	.edit_password_content_list_l{
		width: auto;
		height: 42px;
		line-height: 42px;
		font-weight: 400;
		font-size: 18px;
		color: #666666;
		float: left;
		margin-left: 40px;
		margin-right: 20px;
	}
	.edit_password_content_list_r{
		width: 392px;
		height: 100%;
		float: left;
		border: 1px solid #cccccc;
		border-radius: 6px;
	}
	.edit_password_content_list_r input{
		width: 98%;
		height:40px;
		outline: none;
		border: none;
		font-weight: 400;
		font-size: 16px;
		color: #999999;
		text-indent: 20px;
		margin-left: 4px;
	}
	.edit_password_content_bottom{
		width: 100%;
		overflow: hidden;
		text-align: center;
		line-height: 50px;
		cursor: pointer;
	}
	.edit_password_content_bottom_l{
		width: 150px;
		height: 49px;
		background: #F5F5F5;
		border-radius: 8px;
		float: left;
		font-weight: 400;
		font-size: 20px;
		color: #333333;
		
		margin-top: 24px;
		margin-left: 130px;
	}
	.edit_password_content_bottom_r{
		font-weight: 400;
		font-size: 20px;
		color: #FFFFFF;
		width: 150px;
		height: 49px;
		background: #0068B7;
		border-radius: 8px 8px 8px 8px;
		float: right;
		margin-top: 24px;
		margin-right: 130px;
	}
</style>