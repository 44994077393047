<template>
	<div class="fotter">
		<div class="fotter_content">
			<div class="footer_two_one">
				<h3>易设计</h3>
				<p>众包设计服务平台</p>
			</div>
			<div class="footer_two_two">
				<h6>服务热线</h6>
				<div class="footer_two_two_border"></div>
				<p>{{ContactUs.tel}}</p>
			</div>
			<div class="footer_two_three">
				<p>关于我们</p>
				<ul>
					<li @click="nextclick(1)">关于我们</li>
				</ul>
				<div class="footer_two_three_border"></div>
			</div>
			<div class="footer_two_four">
				<p>常见问题</p>
				<ul>
					<li @click="nextclick(2)">账号管理</li>
					<li @click="nextclick(3)">实名认证</li>
					<li @click="nextclick(4)">设计任务</li>
				</ul>
				<div class="footer_two_four_border"></div>
			</div>
			<div class="footer_two_five">
				<p>支持服务</p>
				<ul>
					<li @click="nextclick(5)">法律声明及隐私权政策</li>
					<li @click="nextclick(6)">设计师平台注册服务协议</li>
					<li @click="nextclick(8)">版权声明</li>
				</ul>
			</div>
			<div class="footer_two_six" @click="beianclick">
				<span>{{ContactUs.web_site_icp}}</span>
				<span>{{ContactUs.web_site_copyright}}</span>
				<span>{{ContactUs.web_site_description}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ContactUs: "", //网站基本信息
			
			}
		},
		mounted() {
			this.getContactUs();		
		},
		methods: {
			//联系我们
			getContactUs(){
				this.$http({
					url: '668f8f9e24d33',
					method: 'post',
					data: JSON.stringify({
						
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.ContactUs = res.data.data;
					}
				});
			},
			
			//关于我们跳转
			nextclick(index){
				let _this = this;
				localStorage.setItem("leftindex",index);
				if(index==1){
					//关于我们
					localStorage.setItem("yijicheck",1)
					this.$router.push({path:"aboutus"})
				}else if(index==2){
					//账户管理
					localStorage.setItem("yijicheck",2)
					this.$router.push({path:"messageManagement"})
				}else if(index==3){
					//实名认真
					localStorage.setItem("yijicheck",2)
					this.$router.push({path:"Realnameauthentication"})
				}else if(index==4){
					//设计任务
					localStorage.setItem("yijicheck",2)
					this.$router.push({path:"designassignment"})
				}else if(index==5){
					//隐私权政策
					localStorage.setItem("yijicheck",3)
					this.$router.push({path:"Privacy"})
				}else if(index==6){
					//服务协议
					localStorage.setItem("yijicheck",3)
					this.$router.push({path:"ServiceAgreement"})
				}else if(index==7){
					//保护政策
					localStorage.setItem("yijicheck",3)
					this.$router.push({path:"protectionpolicy"})
				}else if(index==8){
					//版权
					localStorage.setItem("yijicheck",3)
					this.$router.push({path:"copyright"})
				}
			},
			
			//跳转备案外部链接
			beianclick(){
				window.open("https://beian.mps.gov.cn/")
			}

		}
	}
</script>

<style scoped>
	.fotter {
		width: 100%;
		height: 330px;
		background: #0F1833;
	}

	.fotter_content {
		width: 1200px;
		overflow: hidden;
		margin: 0 auto;
	}
	.footer_two_one {
		width: auto;
		overflow: hidden;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		margin-top: 65px;
		float: left;
		margin-left: 90px;
	}
	.footer_two_one h3{
		font-weight: 400;
		font-size: 32px;
		color: #FFFFFF;
		font-style: oblique;
		margin-bottom: 5px;
	}
	.footer_two_two{
		width:auto;
		overflow: hidden;
		color: #FFFFFF;
		margin-top:65px;
		float: left;
		margin-left: 70px;
	}
	.footer_two_two h6{
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
	}
	.footer_two_two_border{
		width: 20px;
		height: 1px;
		background: #FFFFFF;
		margin-top: 13px;
	}
	.footer_two_two p{
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		margin-top: 23px;
	}
	.footer_two_three{
		width:116px;
		overflow: hidden;
		float: left;
		margin-top: 65px;
		margin-left: 250px;
		color: #FFFFFF;
		position: relative;
	}
	.footer_two_three p{
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
	}
	.footer_two_three ul li{
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		margin-top: 34px;
		cursor: pointer;
	}
	.footer_two_three_border{
		width: 1px;
		height: 20px;
		background: #FFFFFF;
		position: absolute;
		right: 0;
		top: 60px;
	}
	.footer_two_four{
		width: 116px;
		overflow: hidden;
		float: left;
		position: relative;
		margin-top: 65px;
		margin-left: 50px;
	}
	.footer_two_four p{
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
	}
	.footer_two_four ul li{
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		margin-top: 34px;
		cursor: pointer;
	}
	.footer_two_four_border{
		width: 1px;
		height: 61px;
		background: #FFFFFF;
		position: absolute;
		right: 0;
		top: 60px;
	}
	.footer_two_five{
		width: auto;
		overflow: hidden;
		float: left;
		position: relative;
		margin-top: 65px;
		margin-left: 50px;
	}
	.footer_two_five p{
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
	}
	.footer_two_five ul li{
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		margin-top: 34px;
		cursor: pointer;
	}
	.footer_two_six{
		width: 100%;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		float: left;
		text-align: center;
		margin-top: 44px;
		cursor: pointer;
	}
	.footer_two_six span{
		padding-top: 84px;
	}
</style>