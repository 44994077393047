<template>
	<div class="home">
		<HelloWorld></HelloWorld>
		<div class="goods_content">
			<div class="goods_content_one">
				<div class="goods_content_one_l">
					<el-carousel height="485px">
						<el-carousel-item v-for="item in banner">
							<img :src="item.thumb" alt="" @click="swiperclick(item)" />
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
			<div class="two">
				<div class="two_content">
					<div class="two_content_l">
						<img src="../assets/fl9.png" alt="" />
						<span>公告通知</span>
					</div>
					<div class="two_content_r">
						 <el-carousel height="54px" :interval="5000" arrow="always" direction="vertical">
						    <el-carousel-item v-for="(item,index) in NoticeList" :key="index">
						      <div class="" style="width: 100%;height: 54px;line-height: 54px;" @click="Noticeclick(item)">
						      	<span>{{item.title}}</span>
								<span style="padding-left: 8px;">{{item.create_time_text}}</span>
						      </div>
						    </el-carousel-item>
						  </el-carousel>
					</div>
				</div>
			</div>
		</div>
		<div class="three">
			<div class="three_one">
				<p>覆盖多种使用场景</p>
				<div class="three_one_border"></div>
			</div>
			<div class="three_two">
				<div class="three_two_list" v-for="item in changjing">
					<h3>{{item.title}}</h3>
					<div class="three_two_list_one">
						<div class="three_two_list_one_list" v-for="list in item.works">
							<span>{{list}}</span>
						</div>
						<img :src="item.image" alt="" />
					</div>
				</div>
			</div>
		</div>
		
		<div class="four">
			<div class="three_one">
				<p>服务优势</p>
				<div class="three_one_border"></div>
			</div>
			<div class="four_one" v-for="(item,index) in youshi" :key="index" v-if="index==0">
				<div class="four_one_l">
					<img :src="item.image" alt="" />
				</div>
				<div class="four_one_r">
					<span>{{item.title}}</span>
				</div>
			</div>
		</div>
		
		<div class="five">
			<div class="five_one" v-for="(item,index) in youshi" :key="index" v-if="index==1">
				<div class="five_one_r">
					<span>{{item.title}}</span>
				</div>
				<div class="five_one_l">
					<img :src="item.image" alt="" />
				</div>
			</div>
		</div>
		
		<div class="four">
			<div class="four_one" v-for="(item,index) in youshi" :key="index" v-if="index==2">
				<div class="four_one_l">
					<img :src="item.image" alt="" />
				</div>
				<div class="four_one_r">
					<span>{{item.title}}</span>
				</div>
			</div>
		</div>
		
		<div class="five">
			<div class="five_one" v-for="(item,index) in youshi" :key="index" v-if="index==3">
				<div class="five_one_r">
					<span>{{item.title}}</span>
				</div>
				<div class="five_one_l">
					<img :src="item.image" alt="" />
				</div>
			</div>
		</div>
		
		<div class="six">
			<div class="six_one">
				<span>优秀设计案例</span>
			</div>
			<div class="six_two">
				<div class="six_two_list" v-for="item in anlicate" :class="{'six_two_list_now':anlicateaid==item.aid}" @click="anlicateclick(item.aid)">
					<span>{{item.case_classification}}</span>
				</div>
			</div>
			<div class="six_three">
				<img :src="item.image" alt="" v-for="item in anlilist" />
			</div>
		</div>
		<div class="seven">
			<div class="six_one">
				<span>合作伙伴</span>
			</div>
			<div class="seven_two">
				<div class="seven_two_list" v-for="item in huobanlist">
					<img :src="item.image" alt="" />
				</div>
			</div>
		</div>
		<footerBottom></footerBottom>
		<!-- 公告详情弹窗 -->
		<div class="ggdetail" v-if="Noticeshow">
			<div class="ggdetail_content">
				<div class="ggdetail_content_header">
					<span>公告详情</span>
					<img src="../assets/fl59.png" @click="Noticeshow=false" alt="" />
				</div>
				<div class="ggdetail_content_text">
					<h3>{{Noticedetail.title}} <span>{{Noticedetail.create_time}}</span></h3>
					<span v-html="Noticedetail.content">立刻将纱布达拉斯你忙吧</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HelloWorld from '@/components/HelloWorld.vue';
	import footerBottom from '@/components/footerBottom.vue';
	import leftbar from '@/components/leftbar.vue';
	export default {
		name: 'HomeView',
		components: {
			HelloWorld,
			footerBottom,
			leftbar
		},
		data() {
			return {
				banner:[],//轮播
				NoticeList:[],//平台公告列表
				Noticedetail:"",//平台公告详情
				Noticeshow:false,//平台公告详情弹窗
				changjing:[],//覆盖场景
				youshi:[],//服务优势
				anlicate:[],//案例分类
				anlicateaid:"",//案例分类ID
				anlilist:[],//案例列表
				huobanlist:[],//合作伙伴
			}
		},
		computed: {

		},
		create() {},
		mounted() {
			this.get_operationads();//轮播
			this.getNoticeList();//系统文章-获取平台公告列表
			this.get_indexchangjing();//查询使用场景
			this.get_indexyoushi();//服务优势
			this.get_indexanlicate();//案例分类
			this.get_indexhuoban();//合作伙伴
		},
		methods: {
			//获取轮播图
			get_operationads(){
				this.$http({
					url: '641e624160dd0',
					method: 'post',
					data: JSON.stringify({
						type:"1",
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.banner = res.data.data;
					}
				});
			},
			
			//轮播跳转
			swiperclick(item){
				window.location.href = item.href;
			},
			
			//系统文章-获取平台公告列表
			getNoticeList(){
				this.$http({
					url: '6415752f56d0f',
					method: 'post',
					data: JSON.stringify({
						type:"1",
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.NoticeList = res.data.data.data;
					}
				});
			},
			
			//跳转公告详情
			Noticeclick(item){
				this.$http({
					url: '641576c24de11',
					method: 'post',
					data: JSON.stringify({
						article_id:item.aid,
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.Noticedetail = res.data.data;
						this.Noticeshow = true;
					}
				});
			},
			
			//查询使用场景
			get_indexchangjing(){
				this.$http({
					url: '668fa44ab311e',
					method: 'post',
					data: JSON.stringify({
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.changjing = res.data.data;
					}
				});
			},
			
			//查询服务优势
			get_indexyoushi(){
				this.$http({
					url: '668fade97d949',
					method: 'post',
					data: JSON.stringify({
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.youshi = res.data.data;
					}
				});
			},
			
			//案例分类
			get_indexanlicate(){
				this.$http({
					url: '668fae8241433',
					method: 'post',
					data: JSON.stringify({
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.anlicate = res.data.data;
						this.anlicateaid = res.data.data[0].aid;
						this.get_indexanli();
					}
				});
			},
			
			//案例分类切换事件
			anlicateclick(aid){
				this.anlicateaid = aid;
				this.get_indexanli();//查询设计案例
			},
			
			//查询设计案例
			get_indexanli(){
				this.$http({
					url: '668faef64e3f4',
					method: 'post',
					data: JSON.stringify({
						indexanlicate_id:this.anlicateaid
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.anlilist = res.data.data;
					}
				});
			},
			
			//查询合作伙伴
			get_indexhuoban(){
				this.$http({
					url: '668faf44c0dec',
					method: 'post',
					data: JSON.stringify({
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.huobanlist = res.data.data;
					}
				});
			}
		}
	}
</script>
<style scoped>
	.home {
		width: 100%;
		background-color: #fff;
		overflow: hidden;
	}

	.goods_content {
		width:100%;
		overflow: hidden;
		background: #F6F9FF;
	}

	.goods_content_one {
		width: 100%;
		overflow: hidden;
	}

	.goods_content_one_l {
		width:100%;
		height: 485px;
	}
	.goods_content_one_l img{
		width: 100%;
		height: 100%;
	}
	
	.two{
		width: 100%;
		height: 54px;
	}
	.two_content{
		width: 1200px;
		height: 54px;
		margin: 0 auto;
	}
	.two_content_l{
		font-weight: 400;
		font-size: 16px;
		color: #0068B7;
		line-height: 54px;
		float: left;
	}
	.two_content_l img{
		width: 24px;
		height: 24px;
		position: relative;
		top: 6px;
		margin-right: 6px;
	}
	.two_content_r{
		width: 400px;
		height: 100%;
		float: left;
		margin-left: 12px;
		font-weight: 400;
		font-size: 16px;
		color: #666666;
		cursor: pointer;
	}
	.three{
		width: 1200px;
		overflow: hidden;
		margin: 0 auto;
	}
	.three_one{
		width: 100%;
		overflow: hidden;
		font-weight: 400;
		font-size: 40px;
		color: #333333;
		text-align: center;
		margin-top: 60px;
	}
	.three_one_border{
		width: 60px;
		height: 6px;
		background: #0068B7;
		border-radius: 4px 4px 4px 4px;
		margin: 32px auto;
	}
	.three_two{
		width: 100%;
		overflow: hidden;
	}
	.three_two_list{
		width: 280px;
		height: 354px;
		background: #E9EFFF;
		border-radius: 10px;
		float: left;
		text-align: center;
		margin-right: 26px;
		cursor: pointer;
		margin-bottom: 26px;
	}
	.three_two_list:nth-child(4n){
		margin-right: 0;
	}
	.three_two_list h3{
		font-weight: 500;
		font-size: 26px;
		color: #0068B7;
		margin-top: 37px;
	}
	.three_two_list_one{
		width: 224px;
		overflow: hidden;
		margin: 0 auto;
	}
	.three_two_list_one_list{
		width: 104px;
		height: 30px;
		background: #FFFFFF;
		border-radius: 4px;
		font-weight: 400;
		font-size: 16px;
		color: #555555;
		float: left;
		margin-top: 18px;
		line-height: 30px;
		margin-right: 16px;
	}
	.three_two_list_one_list:nth-child(2n){
		margin-right: 0;
	}
	.three_two_list_one img{
		width: 224px;
		height: 155px;
		display: block;
		margin-top: 16px;
		float: left;
	}
	.four{
		width: 1200px;
		overflow: hidden;
		margin: 60px auto;
	}
	.four_one{
		width: 100%;
		overflow: hidden;
		margin-top: 30px;
		position: relative;
	}
	.four_one_l{
		width: 400px;
		height: 334px;
		float: left;
		z-index: 9;
		position: relative;
		margin-left: 60px;
	}
	.four_one_l img{
		width: 100%;
		height: 100%;
	}
	.four_one_r{
		width: 1200px;
		height: 200px;
		background: linear-gradient( 90deg, #FFFFFF 0%, #D8EEFF 100%);
		border-radius: 12px 12px 12px 12px;
		position: absolute;
		right: 0;
		bottom: 0;
		font-weight: 500;
		font-size: 40px;
		color: #333333;
		line-height: 200px;
		text-indent: 700px;
	}
	.five{
		width: 1200px;
		overflow: hidden;
		margin: 0 auto;
	}
	.five_one{
		width: 100%;
		overflow: hidden;
		position: relative;
	}
	.five_one_r{
		width: 1200px;
		height: 200px;
		background: linear-gradient( 270deg, #FFFFFF 0%, #D8EEFF 100%);
		border-radius: 12px 12px 12px 12px;
		font-weight: 500;
		font-size: 40px;
		color: #333333;
		line-height: 200px;
		text-indent: 80px;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	.five_one_l{
		width: 400px;
		height: 352px;
		float: right;
		margin-right: 60px;
		z-index: 9;
		position: relative;
	}
	.five_one_l img{
		width: 100%;
		height: 100%;
	}
	.six{
		width: 1200px;
		overflow: hidden;
		margin: 60px auto;
	}
	.six_one{
		width: 100%;
		overflow: hidden;
		font-weight: 400;
		font-size: 40px;
		color: #333333;
		text-align: center;
	}
	.six_two{
		width: auto;
		overflow: hidden;
		margin: 0 auto;
	}
	.six_two_list{
		width:auto;
		height: 44px;
		background: #F5F5F5;
		border-radius: 40px;
		font-weight: 400;
		font-size: 18px;
		color: #333333;
		line-height: 44px;
		float: left;
		padding-left: 16px;
		padding-right: 16px;
		margin-top: 40px;
		cursor: pointer;
		margin-right: 45px;
	}
	.six_two_list_now{
		background: #0068B7;
		color: #fff;
	}
	.six_three{
		width: 100%;
		overflow: hidden;
		margin: 40px auto;
	}
	.six_three img{
		width: 280px;
		height: 240px;
		border-radius: 12px;
		display: block;
		float: left;
		margin-right: 26px;
		margin-bottom: 24px;
		cursor: pointer;
	}
	.six_three img:nth-child(4n){
		margin-right: 0;
	}
	.seven{
		width: 1200px;
		overflow: hidden;
		margin: 40px auto;
	}
	.seven_two{
		width: 100%;
		overflow: hidden;
		margin: 70px auto;
	}
	.seven_two_list{
		width: 207px;
		height: 75px;
		float: left;
		margin-right: 40px;
		margin-bottom: 30px;
		cursor: pointer;
	}
	.seven_two_list img{
		width: 100%;
		height: 100%;
		border-radius: 12px;
		display: block;
	}
	.seven_two_list:nth-child(5n){
		margin-right: 0;
	}
	.ggdetail{
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 999;
	}
	.ggdetail_content{
		width: 80%;
		height: 70%;
		background-color: #fff;
		margin:10% auto;
		border-radius: 8px;
	}
	.ggdetail_content_header{
		width: 100%;
		height: 64px;
		line-height: 64px;
		font-size: 24px;
		text-indent: 24px;
		position: relative;
	}
	.ggdetail_content_header img{
		width: 32px;
		height: 32px;
		position: absolute;
		right: 24px;
		top: 16px;
		cursor: pointer;
	}
	.ggdetail_content_text{
		width:100%;
		overflow: hidden;
		padding: 24px;
	}
	.ggdetail_content_text h3{
		font-size: 20px;
		padding-bottom: 20px;
	}
	.ggdetail_content_text h3 span{
		font-size: 16px;
		color: #666;
		padding-left: 20px;
	}
</style>