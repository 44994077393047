<template>
	<div>
		<div class="leftbar_content">
			<div class="leftbar_list">
				<div class="leftbar_list_header" @click="yijicheckclick(1)">
					<div class="leftbar_list_header_l" :class="{'leftbar_list_header_l_now':yijicheck==1}">
						<span>关于我们</span>
					</div>
					<div class="leftbar_list_header_r">
						<img src="../assets/fl27.png" alt="" v-if="yijicheck==1" />
						<img src="../assets/fl26.png" alt="" v-else />
					</div>
				</div>
				<div class="leftbar_list_content" v-if="yijicheck==1">
					<div class="leftbar_list_content_list" :class="{'leftbar_list_content_list_now':leftindex==1}" @click="nextclick(1)">
						<span>关于我们</span>
					</div>
				</div>
			</div>
			
			<div class="leftbar_list">
				<div class="leftbar_list_header" @click="yijicheckclick(2)">
					<div class="leftbar_list_header_l" :class="{'leftbar_list_header_l_now':yijicheck==2}">
						<span>常见问题</span>
					</div>
					<div class="leftbar_list_header_r">
						<img src="../assets/fl27.png" alt="" v-if="yijicheck==2" />
						<img src="../assets/fl26.png" alt="" v-else />
					</div>
				</div>
				<div class="leftbar_list_content" v-if="yijicheck==2">
					<div class="leftbar_list_content_list" :class="{'leftbar_list_content_list_now':leftindex==2}" @click="nextclick(2)">
						<span>账户管理</span>
					</div>
					<div class="leftbar_list_content_list" :class="{'leftbar_list_content_list_now':leftindex==3}" @click="nextclick(3)">
						<span>实名认证</span>
					</div>
					<div class="leftbar_list_content_list" :class="{'leftbar_list_content_list_now':leftindex==4}" @click="nextclick(4)">
						<span>设计任务</span>
					</div>
				</div>
			</div>
			
			<div class="leftbar_list">
				<div class="leftbar_list_header" @click="yijicheckclick(3)">
					<div class="leftbar_list_header_l" :class="{'leftbar_list_header_l_now':yijicheck==3}">
						<span>支持服务</span>
					</div>
					<div class="leftbar_list_header_r">
						<img src="../assets/fl27.png" alt="" v-if="yijicheck==3" />
						<img src="../assets/fl26.png" alt="" v-else />
					</div>
				</div>
				<div class="leftbar_list_content" v-if="yijicheck==3">
					<div class="leftbar_list_content_list" :class="{'leftbar_list_content_list_now':leftindex==5}" @click="nextclick(5)">
						<span>法律声明及隐私权政策</span>
					</div>
					<div class="leftbar_list_content_list" :class="{'leftbar_list_content_list_now':leftindex==6}" @click="nextclick(6)">
						<span>设计师平台注册服务协议</span>
					</div>
					<div class="leftbar_list_content_list" :class="{'leftbar_list_content_list_now':leftindex==7}" @click="nextclick(7)">
						<span>儿童人数信息保护政策</span>
					</div>
					<div class="leftbar_list_content_list" :class="{'leftbar_list_content_list_now':leftindex==8}" @click="nextclick(8)">
						<span>版权声明</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			stage_id:String,
			aid:String,
			projectcate_id:String,
		},
		data() {
			return{
				yijicheck:0,//一级分类当前状态
				leftindex:0,//左侧导航栏选中状态
				useridents:"",//企业认证信息
				storestatus:"",//商家入驻状态信息
				typeshow:true,
			}
		},
		mounted() {
			if(localStorage.getItem("yijicheck")){
				this.yijicheck = localStorage.getItem("yijicheck");
			}else{
				this.yijicheck = 0;
			}
			if(localStorage.getItem("leftindex")){
				this.leftindex = localStorage.getItem("leftindex");
			}else{
				this.leftindex = 0;
			}
		},
		methods: {
			//一级分类点击事件
			yijicheckclick(index){
				this.yijicheck = index;
				localStorage.setItem("yijicheck",this.yijicheck)
			},
			
			//左侧导航点击事件
			nextclick(index){
				let _this = this;
				localStorage.setItem("leftindex",index);
				if(index==1){
					//关于我们
					this.$router.push({path:"aboutus"})
				}else if(index==2){
					//账户管理
					this.$router.push({path:"messageManagement"})
				}else if(index==3){
					//实名认真
					this.$router.push({path:"Realnameauthentication"})
				}else if(index==4){
					//设计任务
					this.$router.push({path:"designassignment"})
				}else if(index==5){
					//隐私权政策
					this.$router.push({path:"Privacy"})
				}else if(index==6){
					//服务协议
					this.$router.push({path:"ServiceAgreement"})
				}else if(index==7){
					//保护政策
					this.$router.push({path:"protectionpolicy"})
				}else if(index==8){
					//保护政策
					this.$router.push({path:"copyright"})
				}
			},
		},
	}
</script>

<style scoped>
	.leftbar_content{
		width: 256px;
		height: 730px;
		background-color: #fff;
		text-align: center;
	}
	.leftbar_list{
		width: 100%;
		overflow: hidden;
		text-align: left;
		padding-top: 24px;
	}
	.leftbar_list_header{
		width: 100%;
		overflow: hidden;
		cursor: pointer;
	}
	.leftbar_list_header_l{
		font-weight: 400;
		font-size: 16px;
		color: #333333;
		width: auto;
		float: left;
		margin-left: 24px;
	}
	.leftbar_list_header_l_now{
		font-weight: 500;
		font-size: 16px;
		color: #0068B7;
	}
	.leftbar_list_header_r {
		width: auto;
		float: right;
		margin-right: 24px;
		cursor: pointer;
	}
	.leftbar_list_header_r img{
		width: 24px;
		height: 24px;
	}
	.leftbar_list_content{
		width: 100%;
		overflow: hidden;
		margin-top: 24px;
	}
	.leftbar_list_content_list{
		width: 256px;
		height: 50px;
		background: #fff;
		font-weight: 400;
		font-size: 16px;
		color: #666666;
		line-height: 50px;
		text-indent: 50px;
		cursor: pointer;
	}
	.leftbar_list_content_list_now{
		width: 254px;
		background: #F0F7FF;
		color: #0068B7;
		border-right: 2px solid #0068B7;
	}
</style>